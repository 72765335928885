import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import logo from "../../assets/Image/LogoCompleto.svg";
import openEye from "../../assets/openEye.svg";
import closedEye from "../../assets/closedEye.svg";
import { Button } from "../../components/button";
import { sha512 } from "js-sha512";
import axios from "axios";
import Cookies from "universal-cookie";
import "./hub.css";

function Hub() {
  // #region Variabili
  const [registerActive, setRegisterActive] = useState(false);
  const [innerClass, setInnerClass] = useState("");
  const [containerClass, setContainerClass] = useState("");
  const [errorLogin, setErrorLogin] = useState();
  const [errorRegister, setErrorRegister] = useState();
  const [succ, setSucc] = useState();
  const [passText, setPassText] = useState("password");
  const [passText1, setPassText1] = useState("password");
  const [passImg, setPassImg] = useState(openEye);
  const [passImg1, setPassImg1] = useState(openEye);
  const Cookie = new Cookies(null, { path: "/" });
  const emailLogin = useRef(),
    passwordLogin = useRef(),
    nameRegister = useRef(),
    surnameRegister = useRef(),
    mailRegister = useRef(),
    passwordRegister = useRef(),
    confirmPasswordRegister = useRef(),
    CheckBoxRemember = useRef();
  // #endregion

  //#region funzioni e istruioni
  if (Cookie.get("Login")) window.location.href = "/account";
  if (sessionStorage.getItem("Login")) window.location.href = "/account";

  const CheckRegister = (pass, pass1, email, name, surname) => {
    if (name !== "" && surname !== "") {
      // eslint-disable-next-line
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(email)) {
        if (pass === pass1 && pass !== sha512("")) {
          return true;
        } else {
          setErrorRegister("Password Diverse");
          return false;
        }
      } else {
        setErrorRegister("Inserire una email Valida");
        return false;
      }
    } else {
      setErrorRegister("Inserire Nome e cognome");
      return false;
    }
  };

  const onClickEnter = (e) => {
    if (e.key.toLowerCase() === "enter") Api();
  };

  useEffect(() => {
    document
      .querySelectorAll("input")
      .forEach((x) => x.addEventListener("keydown", onClickEnter));

    return () => {
      document
        .querySelectorAll("input")
        .forEach((x) => x.removeEventListener("keydown", onClickEnter));
    };
  });

  const Api = () => {
    setErrorLogin(null);
    setErrorRegister(null);
    let api = "https://api.hypersave.net:3000";
    if (registerActive === false) {
      api += "/login";
      let mail = emailLogin.current.value;
      let password = sha512(passwordLogin.current.value);
      // eslint-disable-next-line
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(mail)) {
        axios
          .post(api, {
            Email: mail.toLowerCase(),
            Password: password,
          })
          .then((result) => {
            let stringa = result.data;
            if (stringa.esito) {
              let id = stringa.messaggio;
              if (CheckBoxRemember.current.checked)
                Cookie.set("Login", { id: id, pass: password });
              else
                sessionStorage.setItem(
                  "Login",
                  JSON.stringify({ id: id, pass: password })
                );
              setSucc("Login effettuato");
              setTimeout(() => (window.location.href = "/account"), 500);
            } else setErrorLogin(stringa.messaggio);
          })
          .catch((err) => {
            let stringa = err.response;
            let codiceErrore = err.response.status;
            if (codiceErrore === 400) setErrorLogin("Inserire dei dati");
            else setErrorLogin("Errore imprevisto");

            console.log(stringa);
            console.log(codiceErrore);
          });
      } else {
        setErrorLogin("Inserire una email Valida");
      }
    } else {
      api += "/register";
      let name = nameRegister.current.value;
      let surname = surnameRegister.current.value;
      let email = mailRegister.current.value;
      let password = sha512(passwordRegister.current.value);
      let password1 = sha512(confirmPasswordRegister.current.value);
      if (
        CheckRegister(password, password1, email, name, surname) &&
        name !== "" &&
        surname !== ""
      ) {
        axios
          .post(api, {
            Name: name,
            Username: surname,
            Email: email,
            Password: password,
          })
          .then((result) => {
            let stringa = result.data;
            if (stringa.esito) {
              setRegisterActive(false);
              setSucc("Account Creato");
            } else setErrorLogin("Account già esistente");
          })
          .catch((err) => {
            let stringa = err.response.data.error;
            let codiceErrore = err.response.status;
            if (codiceErrore === 400) setErrorRegister("Inserire dei dati");
            else setErrorRegister("Errore imprevisto");
            console.log(stringa);
            console.log(codiceErrore);
          });
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (registerActive === false) {
        setInnerClass("");
        setContainerClass("");
      } else {
        setInnerClass("activeRegister");
        setContainerClass("registerState");
      }
    }, 100);

    return clearInterval;
  }, [registerActive]);
  // #endregion

  const bottoneLogin = {
    className: "bottone",
    text: "Login",
    evento: Api,
  };
  const bottoneRegister = {
    className: "bottone",
    text: "Register",
    evento: Api,
  };

  return (
    <Main>
      <ContainerCard className={containerClass}>
        <CardInner className={innerClass}>
          <Login>
            <Img src={logo} alt="Logo" />
            <Input
              type="text"
              placeholder="Insert Email"
              className="inputEmail"
              ref={emailLogin}
            />
            <password>
              <Input
                type={passText}
                placeholder="Insert Password"
                className="inputPass"
                ref={passwordLogin}
              />
              <PasswordImg
                src={passImg}
                alt="Occhio"
                onClick={() => {
                  if (passText === "password") {
                    setPassText("text");
                    setPassImg(closedEye);
                  } else {
                    setPassText("password");
                    setPassImg(openEye);
                  }
                }}
              />
            </password>
            <Span>
              Don't have an account:{" "}
              <SpanChange
                onClick={() => {
                  setRegisterActive(true);
                  setPassText("password");
                  setPassText1("password");
                  setPassImg(openEye);
                  setPassImg1(openEye);
                }}
              >
                Register
              </SpanChange>
            </Span>
            <RememberContainer>
              <RememberCheck type="checkbox" ref={CheckBoxRemember} />
              <label>Rimanere Connesso</label>
              <RememberSpan>Password forgot?</RememberSpan>
            </RememberContainer>
            <SpanError>{errorLogin}</SpanError>
            <SpanSucc>{succ}</SpanSucc>
            <Button caratteristiche={bottoneLogin} />
          </Login>
          <Register>
            <ImgRegister src={logo} alt="Logo" />
            <Input
              type="text"
              placeholder=" Name"
              className="inputName"
              ref={nameRegister}
            />
            <Input
              type="text"
              placeholder="Surname"
              className="InputName "
              ref={surnameRegister}
            />
            <Input
              type="email"
              placeholder=" Mail"
              className="inputEmail"
              ref={mailRegister}
            />
            <password>
              <Input
                type={passText}
                placeholder="Password"
                className="inputPass"
                ref={passwordRegister}
              />
              <PasswordImg
                src={passImg}
                alt="Occhio"
                onClick={() => {
                  if (passText === "password") {
                    setPassText("text");
                    setPassImg(closedEye);
                  } else {
                    setPassText("password");
                    setPassImg(openEye);
                  }
                }}
              />
            </password>
            <password>
              <Input
                type={passText1}
                placeholder="Confirm password"
                className="inputConfirm"
                ref={confirmPasswordRegister}
              />
              <PasswordImg
                src={passImg1}
                alt="Occhio"
                onClick={() => {
                  if (passText1 === "password") {
                    setPassText1("text");
                    setPassImg1(closedEye);
                  } else {
                    setPassText1("password");
                    setPassImg1(openEye);
                  }
                }}
              />
            </password>

            <Span>
              Already have an account:{" "}
              <SpanChange
                onClick={() => {
                  setRegisterActive(false);
                  setPassText("password");
                  setPassText1("password");
                  setPassImg(openEye);
                  setPassImg1(openEye);
                }}
              >
                Login
              </SpanChange>
            </Span>
            <SpanError>{errorRegister}</SpanError>
            <Button caratteristiche={bottoneRegister} />
          </Register>
        </CardInner>
      </ContainerCard>
    </Main>
  );
}

export default Hub;

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: auto;
  background: linear-gradient(
    100deg,
    rgb(54, 17, 124) 0%,
    rgb(77, 24, 180) 40%,
    rgb(182, 145, 255) 100%
  );

  &::selection {
    background: rgb(182, 145, 255);
    color: rgb(77, 24, 180);
  }
`;

const ContainerCard = styled.div`
  width: 450px;
  aspect-ratio: 1/1.3;
  perspective: 4000px;
  transition: all 0.9s;
  overflow-y: unset;
  margin: 20px;
`;

const CardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: all 1s;
  transform-style: preserve-3d;
`;

const Login = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  backdrop-filter: blur(100px);
  border: #b9b8b89b 1px solid;
  background-color: rgba(255, 255, 255, 0.082);
  box-shadow: 8px 14px 10px rgb(0, 0, 0, 0.4);
  border-radius: 15px;
`;

const RememberContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 11px;
  width: 300px;

  @media (max-width: 345px) {
    width: 250px;
    margin: 5px;
  }
`;

const RememberCheck = styled.input`
  accent-color: rgb(182, 145, 255);
`;

const RememberSpan = styled.span`
  margin-left: auto;
  font-style: oblique;
  cursor: pointer;
  border-bottom: 0.5px solid rgb(134, 94, 212);
  &:hover {
    border-bottom: 0.5px solid rgb(210, 205, 219);
  }
  user-select: none;
`;

const Register = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: rotateY(180deg);
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  backdrop-filter: blur(100px);
  border: #b9b8b89b 1px solid;
  background-color: rgba(255, 255, 255, 0.082);
  box-shadow: 8px 14px 10px rgb(0, 0, 0, 0.4);
  border-radius: 15px;
  @media (max-width: 330px) {
    height: 105%;
  }
`;

const Input = styled.input`
  border: 0;
  border-bottom: white 1px solid;
  background-color: transparent;
  width: 250px;
  margin-bottom: 25px;
  outline: none;
  padding: 10px;
  font-size: 1.1rem;

  &::placeholder {
    color: white;
    font-size: 1.1em;
  }
  @media (max-width: 450px) {
    margin-bottom: 20px;
  }
  @media (max-width: 375px) {
    font-size: 1rem;
  }
  @media (max-width: 355px) {
    margin-bottom: 10px;
    font-size: 0.9rem;
  }
`;

const PasswordImg = styled.img`
  aspect-ratio: 1/1;
  width: 30px;
  position: absolute;
  @media (max-width: 370px) {
    transform: translateX(-30px);
  }
`;

const Img = styled.img`
  position: relative;
  margin-bottom: 60px;
  width: 300px;
  aspect-ratio: 2/1;

  @media screen and (max-width: 450px) {
    width: 200px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 375px) {
    margin-bottom: 10px;
    width: 150px;
  }
`;

const ImgRegister = styled.img`
  position: relative;
  margin-bottom: 10px;
  width: 300px;
  aspect-ratio: 2/1;
  @media (max-width: 435px) {
    width: 200px;
    margin-bottom: 5px;
  }
  @media (max-width: 375px) {
    width: 180px;
    margin: 0;
  }
`;

const Span = styled.span`
  font-size: 0.8rem;
  margin-bottom: 10px;
`;

const SpanChange = styled.span`
  cursor: pointer;
  border-bottom: 0.5px solid rgb(134, 94, 212);
  &:hover {
    border-bottom: 0.5px solid rgb(210, 205, 219);
  }
  user-select: none;
`;

const SpanError = styled.span`
  color: sandybrown;
  margin-top: 5px;
`;

const SpanSucc = styled.span`
  color: lightgreen;
  margin-top: 5px;
`;
