import styled from "styled-components";
import bigliettoVisita from "../assets/FRONT SENZA LINEA.svg";
import "./home.css";

export const Contact = () => {
  return (
    <Wrapper className="App">
      <ImgBiglietto src={bigliettoVisita} alt="Visita" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  text-align: center;
  background: linear-gradient(
    100deg,
    rgb(54, 17, 124) 0%,
    rgb(77, 24, 180) 40%,
    rgb(182, 145, 255) 100%
  );
  margin: 0;
  padding: 0;
  height: 100dvh;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ImgBiglietto = styled.img`
  width: 50%;
  height: auto;
  margin: 100px;

  @media (max-width: 700px) {
    width: 100%;
    margin: 0;
    rotate: 90deg;
  }
`;
