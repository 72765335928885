import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Home from "../assets/ICON_Home.svg";
import Setting from "../assets/ICON_Settings.svg";
import Cookies from "universal-cookie";
import { HomePage, Settings, Key, Error } from "../components/accountPages";
import axios from "axios";

export const Account = () => {
  const Cookie = new Cookies();
  const sessionData = JSON.parse(sessionStorage.getItem("Login"));
  const cookieData = Cookie.get("Login");
  if (!cookieData && !sessionData) window.location.href = "/hub";
  let data = useRef({});
  if (cookieData) {
    data.current.id = cookieData.id;
    data.current.pass = cookieData.pass;
  } else {
    data.current.id = sessionData.id;
    data.current.pass = sessionData.pass;
  }

  const [Tilte, setTile] = useState("Profile");
  const [page, setPage] = useState();
  const [navActive, setNavActive] = useState({
    home: "active",
    settings: "",
    key: "",
    logout: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let url = `https://api.hypersave.net:3000/account/${data.current.id}?pass=${data.current.pass}`;
    axios
      .get(url)
      .then((result) => {
        if (result.data.esito.length !== 0) {
          for (let x in result.data.esito) {
            data.current[x] = result.data.esito[x];
          }
          setIsLoading(false);
          setPage(<HomePage data={data.current} />);
        } else {
          Cookie.remove("Login");
          sessionStorage.removeItem("Login");
        }
      })
      .catch((err) => {
        if (err) setPage(<Error />);
      });
    //eslint-disable-next-line
  }, []);

  if (isLoading)
    return (
      <Wrapper>
        <Loading>
          Loading <div>.</div> <div>.</div> <div>.</div>
        </Loading>
      </Wrapper>
    );
  else
    return (
      <Wrapper>
        <AccountConteiner>
          <NavContainer>
            <NavItem
              onClick={() => {
                setNavActive({
                  home: "active",
                  settings: "",
                  key: "",
                  logout: "",
                });
                setTile("Profile");
                setPage(<HomePage data={data.current} />);
              }}
              className={navActive.home}
            >
              <img src={Home} alt="Home" />
              <span>Profile</span>
            </NavItem>
            <NavItem
              onClick={() => {
                setNavActive({
                  home: "",
                  settings: "active",
                  key: "",
                  logout: "",
                });
                setTile("Settings");
                setPage(<Settings />);
              }}
              className={navActive.settings}
            >
              <img src={Setting} alt="Settings" />
              <span>Settings</span>
            </NavItem>
            <NavItem
              onClick={() => {
                setNavActive({
                  home: "",
                  settings: "",
                  key: "active",
                  logout: "",
                });
                setTile("Key");
                setPage(<Key />);
              }}
              className={navActive.key}
            >
              <img src={Home} alt="Home" />
              <span>Key</span>
            </NavItem>
            <NavItem
              onClick={() => {
                setNavActive({
                  home: "",
                  settings: "",
                  key: "",
                  logout: "active",
                });
                if (cookieData) Cookie.remove("Login");
                if (sessionData) sessionStorage.removeItem("Login");
                window.location.href = "/hub";
              }}
              className={navActive.logout}
            >
              <img src={Setting} alt="Settings" />
              <span>Logout</span>
            </NavItem>
          </NavContainer>
          <AccountContent>
            <AccountTitle>{Tilte}</AccountTitle>
            {page}
          </AccountContent>
        </AccountConteiner>
      </Wrapper>
    );
};

const Wrapper = styled.div`
  background: linear-gradient(
    100deg,
    rgb(54, 17, 124) 0%,
    rgb(77, 24, 180) 40%,
    rgb(182, 145, 255) 100%
  );
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = styled.div`
  font-size: larger;
  display: flex;
  gap: 5px;
  @keyframes up-down {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  div {
    width: min-content;
  }
  & div:nth-child(1) {
    animation: up-down 2s ease-in-out 0.5s infinite;
  }
  & div:nth-child(2) {
    animation: up-down 2s ease-in-out 1s infinite;
  }
  & div:nth-child(3) {
    animation: up-down 2s ease-in-out 1.5s infinite;
  }
`;

const AccountConteiner = styled.div`
  background-color: #1e1e2b;
  width: 1000px;
  height: 700px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  box-shadow: 0 0 20px 5px #1e1e2b;
`;

const AccountTitle = styled.h1``;

const AccountContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavContainer = styled.ul`
  background-color: #24273d;
  height: 600px;
  width: 200px;
  margin: 10px;
  margin-top: 0;
  margin-left: 20px;
  border-radius: 20px;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .active {
    background-color: #6030bd;
  }
`;

const NavItem = styled.li`
  margin: 10px;
  margin-bottom: 50px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 5px;
  border-radius: 8px;
  transition: 0.5s all;
  img {
    margin-right: 5px;
    height: 25px;
    width: 25px;
  }
  &:hover {
    background-color: #6030bd;
    transition: 0.5s all;
  }
`;
