import React from "react";
import Home1 from "./pages/home1";
import Home from "./pages/home";
import Hub from "./pages/Login-register/hub";
import { Contact } from "./pages/contact";
import { Account } from "./pages/account";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/index1" element={<Home1 />} />
          <Route path="/hub" element={<Hub />} />
          <Route path="/account" element={<Account />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
