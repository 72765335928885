import React from "react";

export const Button = ({ caratteristiche }) => {
  return (
    <button
      className={caratteristiche.className}
      type="button"
      style={{ userSelect: "none !important" }}
      onClick={caratteristiche.evento}
      id={caratteristiche.id}
    >
      {caratteristiche.text}
    </button>
  );
};

export const ButtonUi = ({ caratteristiche }) => {
  return (
    <button
      className={caratteristiche.className}
      type="button"
      style={{ userSelect: "none !important" }}
      onClick={caratteristiche.evento}
      id={caratteristiche.id}
    >
      <img
        src={caratteristiche.icona}
        alt="bho"
        className="iconeBottoniUi"
        onClick={caratteristiche.evento}
        id={caratteristiche.id}
      />
    </button>
  );
};
