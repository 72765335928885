import "./home.css";
import { NavBar } from "../components/navbar";
import GraficPresetation from "../assets/Image/iper_salvatore_grafica.png";
import divano from "../assets/Image/divano.png";
import stonks from "../assets/Image/stonks.png";
import porcello from "../assets/Image/porcello(odio osio padre).png";
import UI from "../assets/Image/UiNuova.svg";
import tempAccount from "../assets/user_sample.svg";
import { Button } from "../components/button";
import { useEffect, useState } from "react";
import { Card } from "../components/card";
import { Private, Enterprise } from "../components/card_end";
import check from "../assets/Image/check.svg";
import { Footer } from "../components/footer";
import instagram from "../assets/icons8-instagram.svg";
import tiktok from "../assets/icons8-tiktok.svg";
import youtube from "../assets/icons8-youtube.svg";
import twitter from "../assets/twitter-154-svgrepo-com.svg";
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import { EffectCards, Mousewheel } from "swiper/modules";
import "swiper/css";
import React from "react";
import VanillaTilt from "vanilla-tilt";
import { computePosition, offset } from "@floating-ui/react";
import { initializeApp } from "firebase/app";
import { doc, setDoc, getFirestore } from "firebase/firestore";

//#region Presentation
const Presentation = ({ height }) => {
  const swiper = useSwiper();

  const eventoBottone = () => {
    swiper.slideTo(1);
  };

  const bottone_Presentation = {
    text: "Learn More",
    className: "presentation_button",
    evento: eventoBottone,
  };

  const swiperSlide = useSwiperSlide();
  const [animation, setAnimation] = useState("");

  useEffect(() => {
    setTimeout(() => {
      swiperSlide.isActive ? setAnimation("animation") : setAnimation("");
    }, 500);

    return clearInterval;
  }, [swiperSlide.isActive]);

  return (
    <div className="presentation" style={{ height: height }}>
      <img
        src={GraficPresetation}
        alt="Grafica"
        className="presentation_image"
      />
      <div className={"presentation_item " + animation}>
        <h2 className="presentation_title ">The Easiest way</h2>
        <p className="presentation_text">
          to reduce your computer Consumption!
        </p>
        <Button caratteristiche={bottone_Presentation} />
      </div>
    </div>
  );
};

const PresentationMobile = () => {
  const bottone_PresentationMobile = {
    text: "Learn More",
    className: "presentation_button",
  };

  return (
    <div className="presentation">
      <img
        src={GraficPresetation}
        alt="Grafica"
        className="presentation_image"
      />
      <div className="presentation_item ">
        <h2 className="presentation_title">The Easiest way</h2>
        <p className="presentation_text">
          to reduce your computer Consumption!
        </p>
        <a href="#card_div">
          <Button caratteristiche={bottone_PresentationMobile} />
        </a>
      </div>
    </div>
  );
};

//#endregion

//#region Card
const CardSection = ({ height }) => {
  const Card_content = [
    {
      title: "EFFICIENCY",
      img: stonks,
      text: "HyperSave is completely automatic, it detects when you are not using the computer.",
    },
    {
      title: "SIMPLICITY",
      img: divano,
      text: "Slick and Intuitive UI. Explore and get to know the program with just a few clicks.",
    },
    {
      title: "SAVE UP",
      img: porcello,
      text: "The save mode start without the user touching a key, making it easy and convientent to use.",
    },
  ];

  const swiperSlide = useSwiperSlide();
  const [animation, setAnimation] = useState("");

  useEffect(() => {
    setTimeout(() => {
      swiperSlide.isActive ? setAnimation("animation") : setAnimation("");
    }, 500);

    return clearInterval;
  }, [swiperSlide.isActive]);

  return (
    <div className="card_div" style={{ height: height }}>
      <h2 className={"card_div_title " + animation}>
        Why would you choose HyperSave?
      </h2>
      <p className={"card_div_subtitle " + animation}>
        Here is what we can offer you!
      </p>
      <div className="card_container">
        <Card c={Card_content[0]} />
        <Card c={Card_content[1]} />
        <Card c={Card_content[2]} />
      </div>
    </div>
  );
};

const CardSectionMobile = () => {
  const Card_content = [
    {
      title: "EFFICIENCY",
      img: stonks,
      text: "HyperSave is completely automatic, it detects when you are not using the computer.",
    },
    {
      title: "SIMPLICITY",
      img: divano,
      text: "Slick and Intuitive UI. Explore and get to know the program with just a few clicks.",
    },
    {
      title: "SAVE UP",
      img: porcello,
      text: "The save mode start without the user touching a key, making it easy and convientent to use.",
    },
  ];

  return (
    <div className="card_div" id="card_div">
      <div>
        <h2 className="card_div_title ">Why would you choose HyperSave</h2>
        <p className="card_div_subtitle">Here is what we can offer you!</p>
      </div>
      <Swiper
        autoHeight="true"
        width={230}
        effect={"cards"}
        modules={[EffectCards]}
        centeredSlidesBounds={true}
        centeredSlides={true}
        loop={true}
      >
        <SwiperSlide>
          <Card c={Card_content[0]} />
        </SwiperSlide>
        <SwiperSlide>
          <Card c={Card_content[1]} />
        </SwiperSlide>
        <SwiperSlide>
          <Card c={Card_content[2]} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
//#endregion

//#region Ui
const Ui = ({ height }) => {
  let ArrayTesto = [
    `The Analytics section offers a comprehensive control center for monitoring energy consumption and computer activity. It provides detailed, real-time analysis in an easy-to-understand format. 
    Users can view energy usage, track computer activity, and identify energy-consuming applications whenever they want. Intuitive graphs and detailed statistics give a clear view of energy consumption and computer usage.
    Additionally, it shows savings from efficient energy use, helping users optimize and save money.`,

    `Welcome in the “Home” section, where you’ll have your first experience with our product. In this page are displayed all the app’s main functions and device information, such as the software’s power button and reports on device’s efficiency.
    Furthermore all data are supplied in the most simple and intuitive way and it is possible to manage some of the application’s settings directly through this tab.`,

    `In the Subscription plans section users will be able to see what plan they chose and even explore the other solutions we offer. 
    Thanks to this simple but eye catching card-like disposition you’ll be able to understand every plan and choose the best for you in a matter of time.
    And if you don’t lie your tier you can always change it.`,

    `Settings section is where all the magic takes place, because in this tab users can fix software’s settings, customizing it in all its aspects based on people devices and preferences. 
    In this tab not only you’ll be able to manage application’s general behavior, but also choose which programs you don’t want interacting with it, just with a few clicks.`,
  ];
  let [testo, setTesto] = useState(ArrayTesto[0]);

  const cambioTesto = (e) => {
    setTesto(ArrayTesto[Number(e.target.id)]);
  };

  let caratteristicheBottoni = [
    {
      className: "BtnUi",
      evento: cambioTesto,
      id: "0",
      text: "HOME",
    },
    {
      className: "BtnUi",
      evento: cambioTesto,
      id: "1",
      text: "ANALYTICS",
    },
    {
      className: "BtnUi",
      evento: cambioTesto,
      id: "2",
      text: "SUB",
    },
    {
      className: "BtnUi",
      evento: cambioTesto,
      id: "3",
      text: "SETTING",
    },
  ];
  const bottoni = caratteristicheBottoni.map((Element) => {
    return <Button caratteristiche={Element} key={Element.id} />;
  });

  const swiperSlide = useSwiperSlide();
  const [animation, setAnimation] = useState("");

  useEffect(() => {
    setTimeout(() => {
      swiperSlide.isActive ? setAnimation("animation") : setAnimation("");
    }, 500);

    return clearInterval;
  }, [swiperSlide.isActive]);
  return (
    <div className="UI" style={{ height: height }}>
      <h1 className={"UiTile " + animation}>Our Graphical User Interface</h1>
      <div className="UiBtnContainer">{bottoni}</div>
      <div className="UIContainer">
        <img src={UI} alt="UI" className="UiImage" />
        <p className="UIText">{testo}</p>
      </div>
    </div>
  );
};

const UiMobile = () => {
  let ArrayTesto = [
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam quia deleniti quod, molestiae necessitatibus quo libero facilis magni iste nam earum mollitia assumenda dolore debitis sint voluptatum repellat! Est, architecto? Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam quia deleniti quod, molestiae necessitatibus quo libero facilis magni iste nam earum mollitia assumenda dolore debitis sint voluptatum repellat! Est, architecto?",
    "testo2",
    "testo3",
    "testo4",
  ];
  let [testo, setTesto] = useState(ArrayTesto[0]);

  const cambioTesto = (e) => {
    setTesto(ArrayTesto[Number(e.target.id)]);
  };

  let caratteristicheBottoni = [
    {
      className: "BtnUi",
      evento: cambioTesto,
      id: "0",
      text: "HOME",
    },
    {
      className: "BtnUi",
      evento: cambioTesto,
      id: "1",
      text: "ANALYTICS",
    },
    {
      className: "BtnUi",
      evento: cambioTesto,
      id: "2",
      text: "SUB",
    },
    {
      className: "BtnUi",
      evento: cambioTesto,
      id: "3",
      text: "SETTING",
    },
  ];
  const bottoni = caratteristicheBottoni.map((Element) => {
    return <Button caratteristiche={Element} key={Element.id} />;
  });
  return (
    <div className="UI">
      <h1 className="UiTile">Our Graphical User Interface</h1>
      <div className="UIContainer">
        <div className="UiContent">
          <div className="UiBtnContainer">{bottoni}</div>
          <div className="linea" />
          <p className="UIText">{testo}</p>
        </div>
        <img src={UI} alt="UI" className="UiImage" />
      </div>
    </div>
  );
};
//#endregion

//#region End
const End = ({ height }) => {
  const infoPrivate = [
    {
      img: check,
      text: "Reduced consumption",
      key: "consumption",
    },
    {
      img: check,
      text: "Fully customizable",
      key: "customizable",
    },
    {
      img: check,
      text: "complete Analitics",
      key: "Analitics",
    },
  ];

  const infoEnterprise = [
    {
      img: check,
      text: "All basic features",
      key: "features",
    },
    {
      img: check,
      text: "Company analytics",
      key: "analytics",
    },
    {
      img: check,
      text: "Consumption forecasting",
      key: "forecasting",
    },
    {
      img: check,
      text: "Computer optimization",
      key: "optimization",
    },
  ];

  const swiperSlide = useSwiperSlide();
  const [animation, setAnimation] = useState("");

  useEffect(() => {
    setTimeout(() => {
      swiperSlide.isActive ? setAnimation("animation") : setAnimation("");
    }, 500);

    return clearInterval;
  }, [swiperSlide.isActive]);

  const PrivateButton = { className: "email_button", text: "Sign Up" };
  const EnterpriseButton = { className: "button", text: "Learn More" };

  return (
    <div className="fine" style={{ height: height }}>
      <div className={"end_text " + animation}>
        <h1 className="end_title">TRY OUR PRODUCT</h1>
        <p className="end_subtitle">Chose one of our plans:</p>
      </div>
      <div className="info_section">
        <Private
          listaPrivate={infoPrivate}
          caratteristicheBottone={PrivateButton}
        />
        <Enterprise
          listaEnterprise={infoEnterprise}
          caratteristicheBottone={EnterpriseButton}
        />
      </div>
    </div>
  );
};

const EndMobile = () => {
  const infoPrivate = [
    {
      img: check,
      text: "Reduced consumption",
      key: "consumption",
    },
    {
      img: check,
      text: "Fully customizable",
      key: "customizable",
    },
    {
      img: check,
      text: "complete Analitics",
      key: "Analitics",
    },
  ];

  const infoEnterprise = [
    {
      img: check,
      text: "All basic features",
      key: "features",
    },
    {
      img: check,
      text: "Company analytics",
      key: "analytics",
    },
    {
      img: check,
      text: "Consumption forecasting",
      key: "forecasting",
    },
    {
      img: check,
      text: "Computer optimization",
      key: "optimization",
    },
  ];

  const PrivateButton = { className: "email_button", text: "Sign Up" };
  const EnterpriseButton = { className: "button", text: "Learn More" };

  return (
    <div className="fine">
      <div className={"end_text "}>
        <h1 className="end_title">TRY OUR PRODUCT</h1>
        <p className="end_subtitle">Chose one of our plans:</p>
      </div>
      <div className="info_section">
        <Private
          listaPrivate={infoPrivate}
          caratteristicheBottone={PrivateButton}
        />
        <Enterprise
          listaEnterprise={infoEnterprise}
          caratteristicheBottone={EnterpriseButton}
        />
      </div>
    </div>
  );
};

//#endregion

function Home1() {
  const [windowsSize, setwindowsSize] = useState(getWindosSize());

  useEffect(() => {
    function windowsResize() {
      setwindowsSize(getWindosSize());
    }

    window.addEventListener("resize", windowsResize);

    return () => {
      window.removeEventListener("resize", windowsResize);
    };
  });

  //bottone nav
  useEffect(() => {
    if (windowsSize.innerWidth > 900) {
      const swiper = document.querySelector(".swiperBody").swiper;
      document.getElementById("nav_button").addEventListener("click", () => {
        swiper.slideTo(2, 900);
      });
      VanillaTilt.init(document.querySelectorAll(".card"));
    }
  });

  const nav = {
    bottone: {
      text: "Download",
      className: "nav_button",
      id: "nav_button",
    },
    img: {
      src: tempAccount,
      click: () => (window.location = "/hub"),
      style: {},
    },
  };

  //email checker
  useEffect(() => {
    //tooltip
    const input_email = document.querySelector(".email_input");
    const tooltip = document.querySelector(".tooltip");
    function update() {
      computePosition(input_email, tooltip, {
        middleware: [offset(6)],
      }).then(({ x, y }) => {
        Object.assign(tooltip.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      });
    }

    function showTooltip() {
      tooltip.style.display = "block";
      update();
    }

    function hideTooltip() {
      tooltip.style.display = "";
    }

    const validateEmail = (email) => {
      return email.match(
        // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    const validate = () => {
      const tooltip = document.querySelector(".tooltip");
      const email = document.querySelector(".email_input").value;

      if (validateEmail(email)) {
        showTooltip();
        tooltip.innerHTML = email + " is valid.";
        tooltip.style.background = "rgb(3, 212, 3)";
        let x = document.querySelector(".email_button");
        x.addEventListener("click", AddtoDatabase);
      } else if (!validateEmail(email) && String(email).length > 0) {
        showTooltip();
        tooltip.innerHTML = email + " is invalid.";
        tooltip.style.background = "rgb(196, 25, 13)";
        let x = document.querySelector(".email_button");
        x.addEventListener("click", null);
      } else if (document.querySelector(".email_input").value === "") {
        hideTooltip();
        let x = document.querySelector(".email_button");
        x.addEventListener("click", null);
      }
      return false;
    };

    document.querySelector(".email_input").addEventListener("input", validate);

    //Add to database a record
    function AddtoDatabase() {
      const firebaseConfig = {
        apiKey: "AIzaSyAQVwWOifLeGGT-gHvokkwQD64vM9l9PPc",
        authDomain: "hypersavedb.firebaseapp.com",
        databaseURL:
          "https://hypersavedb-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "hypersavedb",
        storageBucket: "hypersavedb.appspot.com",
        messagingSenderId: "1036183137241",
        appId: "1:1036183137241:web:ec08d83a4cce11a653c7c8",
        measurementId: "G-CPX7L8KB4E",
      };

      const email = document.querySelector(".end_input").value;
      //const passwrd = md5(document.querySelector("#Pass").value)
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const database = getFirestore(app);
      setDoc(doc(database, "Emails", email), {
        role: "Beta tester",
        platform: "Desktop",
        //pass: passwrd
      }).then(() => {
        alert("Subscribed successfully to Beta Test Program!");
      });
    }
  });

  //social
  const listaSocial = [
    { name: "youtube", img: youtube, href: "" },
    {
      name: "insta",
      img: instagram,
      href: "https://www.instagram.com/hypersaveofficial/",
    },
    { name: "tiktok", img: tiktok, href: "" },
    { name: "twitter", img: twitter, href: "https://twitter.com/home?lang=en" },
  ];

  if (windowsSize.innerWidth < 900) {
    return (
      <div className="App">
        <NavBar caratteristiche={nav} />
        <PresentationMobile />
        <CardSectionMobile />
        <UiMobile />
        <EndMobile />
        <Footer listaSocial={listaSocial} />
      </div>
    );
  } else {
    window.scrollTo(0, 0);
    return (
      <div className="App">
        <NavBar caratteristiche={nav} />
        <Swiper
          direction="vertical"
          mousewheel={true}
          autoHeight={true}
          speed={900}
          modules={[Mousewheel]}
          simulateTouch={false}
          className="swiperBody"
        >
          <SwiperSlide>
            <Presentation height={windowsSize.innerHeight - 170} />
          </SwiperSlide>
          <SwiperSlide>
            <CardSection height={windowsSize.innerHeight - 127} />
          </SwiperSlide>
          <SwiperSlide>
            <Ui height={windowsSize.innerHeight - 220} />
          </SwiperSlide>
          <SwiperSlide>
            <End height={windowsSize.innerHeight - 270} />
            <Footer listaSocial={listaSocial} />
          </SwiperSlide>
        </Swiper>
      </div>
    );
  }
}

function getWindosSize() {
  const { innerHeight, innerWidth } = window;

  return { innerHeight, innerWidth };
}

export default Home1;
