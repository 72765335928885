import React from "react";



export const Card = (c) =>{
    return(
        <div className="card">
            <div className="content">
                <h1 className="card_title">{c.c.title}</h1>
                <img src={c.c.img} alt="img" className="card_image" />
                <p className="card_text">{c.c.text}</p>
            </div>
        </div>
    )
}