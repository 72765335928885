import { Button } from "./button";
import React from "react";

export const Private = ({ listaPrivate, caratteristicheBottone }) => {

  const resultPrivate = listaPrivate.map((element) => (
    <div key={element.key} className="info_container">
      <img src={element.img} alt="" className="info_image" />
      <p className="info_text">{element.text}</p>
    </div>
  ));

  return (
    <div className="private">
      <div className="title">PRIVATE </div>
      <div className="linea" />
      <div>{resultPrivate}</div>
      <div className="email">
        <span className="txt_email">Request the join for the beta program:</span>
        <input type="text" className="email_input" />
        <span style={{userSelect: "none"}} className="txt_input">E-mail</span>
        <Button caratteristiche={caratteristicheBottone} />
        <div className="tooltip"> </div>
       
      </div>
    </div>
  );
};

export const Enterprise = ({listaEnterprise, caratteristicheBottone}) => {

    const resultEnterprise = listaEnterprise.map((element) => (
        <div key={element.key} className="info_container">
          <img src={element.img} alt="" className="info_image" />
          <p className="info_text">{element.text}</p>
        </div>
        ))
  return (
    <div className="enterprise">
      <div className="title">ENTERPRISE</div>
      <div className="linea" />
      <div>{resultEnterprise}</div>
      <span className="txt_button">Request the join for the beta program:</span>
      <Button caratteristiche={caratteristicheBottone} />
      <span></span>
    </div>
  );
};
