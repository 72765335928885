import React from "react";

export const Footer = ({listaSocial}) =>{

    const resultSocial = listaSocial.map((element) => (<div key={element.name} className={element.name}><a href={element.href}><img src={element.img} alt={element.name} /></a></div>));

    return(
        <footer>
            <div className="footer_social">
                {resultSocial}
            </div>
            <p className="rights">All rights reserved ®</p>
        </footer>
        
        )
}