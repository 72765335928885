import React, { useRef, useState } from "react";
import styled from "styled-components";
import user from "../assets/user_sample_black.svg";
import pencil from "../assets/penicl.svg";
import axios from "axios";
import Cookies from "universal-cookie";
import { sha512 } from "js-sha512";

//#region Home
export const HomePage = ({ data }) => {
  const [userText, setUserText] = useState("Modifica");
  const [credentialText, setCredentialText] = useState("Modifica");
  const [removeMessage, setRemoveMessage] = useState(null);
  const Cookie = new Cookies();
  const nameInput = useRef();
  const surnameInput = useRef();
  const emailInput = useRef();
  const passInput = useRef();
  const userUndo = useRef();
  const credentialUndo = useRef();

  function api(dati) {
    axios
      .put(`https://api.hypersave.net:3000/account/${data.id}`, dati)
      .then((result) => {
        if (result.data.esito) {
          if (dati.nome) {
            setUserText("Modificati con successo");
            data.name = dati.nome;
            data.surname = dati.cognome;
            nameInput.current.value = data.name;
            surnameInput.current.value = data.surname;
          }
          if (dati.email) {
            setCredentialText("Modificati con successo");
            data.email = dati.email;
            data.pass = dati.passNuova;
            emailInput.current.value = data.email;
            passInput.current.value = "";
            if (Cookie.get("Login"))
              Cookie.set("Login", { id: data.id, pass: data.pass });
            if (sessionStorage.getItem("Login"))
              sessionStorage.setItem(
                "Login",
                JSON.stringify({
                  id: data.id,
                  pass: data.pass,
                })
              );
          }
        } else {
          if (dati.nome) setUserText(result.data.messaggio);
          if (dati.email) setCredentialText(result.data.messaggio);
        }
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          if (dati.nome) setUserText("Errore imprevisto");
          if (dati.email) setCredentialText("Errore imprevisto");
        }
      });
    setTimeout(() => {
      setUserText("Modifica");
      setCredentialText("Modifica");
    }, 2000);
  }

  function changeUser() {
    if (userText === "Modifica") {
      setUserText("Invia");
      nameInput.current.disabled = false;
      surnameInput.current.disabled = false;
      userUndo.current.classList.add("active");
    } else {
      userUndo.current.classList.remove("active");
      let nome = nameInput.current.value;
      let cognome = surnameInput.current.value;
      if (nome !== "" && cognome !== "") {
        setUserText("Loading...");
        nameInput.current.disabled = true;
        surnameInput.current.disabled = true;
        let dati = {
          nome: nome,
          cognome: cognome,
          passVecchia: data.pass,
        };
        api(dati);
      } else {
        setUserText("Valori errati");
      }
    }
  }

  function changeCredential() {
    if (credentialText === "Modifica") {
      setCredentialText("Invia");
      emailInput.current.disabled = false;
      passInput.current.disabled = false;
      credentialUndo.current.classList.add("active");
    } else {
      credentialUndo.current.classList.remove("active");
      let mail = emailInput.current.value;
      let pass = passInput.current.value;
      if (
        // eslint-disable-next-line
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(mail) &&
        mail !== ""
      ) {
        setCredentialText("Loading...");
        emailInput.current.disabled = true;
        passInput.current.disabled = true;
        let dati = {
          email: mail,
          passNuova: pass !== "" ? sha512(passInput.current.value) : data.pass,
          passVecchia: data.pass,
        };
        api(dati);
      } else {
        setCredentialText("Valori errati");
      }
    }
  }
  return (
    <HomeContainer>
      <div>
        <User>
          <UserImage src={user} alt="user" />
        </User>
        <Dot>
          <DotImage src={pencil} alt="Pencil" />
        </Dot>
      </div>
      <div>
        <SectionTitle>Personal Data</SectionTitle>
        <label htmlFor="nome">Nome</label>
        <Input
          defaultValue={data.name}
          type="text"
          name="nome"
          disabled
          ref={nameInput}
        />
        <label htmlFor="cognome">Cognome</label>
        <Input
          defaultValue={data.surname}
          type="text"
          name="cognome"
          disabled
          ref={surnameInput}
        />
        <ButtonContainer>
          <SectionButton onClick={changeUser}>{userText}</SectionButton>
          <UndoButton
            ref={userUndo}
            onClick={(e) => {
              setUserText("Modifica");
              e.currentTarget.classList.remove("active");
              nameInput.current.disabled = true;
              surnameInput.current.disabled = true;
              nameInput.current.value = nameInput.current.defaultValue;
              surnameInput.current.value = surnameInput.current.defaultValue;
            }}
          >
            X
          </UndoButton>
        </ButtonContainer>
      </div>
      <div>
        <SectionTitle>Credentials</SectionTitle>
        <label htmlFor="email">Email</label>
        <Input
          defaultValue={data.email}
          type="email"
          name="email"
          disabled
          ref={emailInput}
        />
        <label htmlFor="pass">Password</label>
        <Input
          defaultValue={""}
          type="password"
          name="password"
          disabled
          ref={passInput}
        />
        <ButtonContainer>
          <SectionButton onClick={changeCredential}>
            {credentialText}
          </SectionButton>
          <UndoButton
            ref={credentialUndo}
            onClick={(e) => {
              setCredentialText("Modifica");
              e.currentTarget.classList.remove("active");
              passInput.current.disabled = true;
              emailInput.current.disabled = true;
              passInput.current.value = passInput.current.defaultValue;
              emailInput.current.value = emailInput.current.defaultValue;
            }}
          >
            X
          </UndoButton>
        </ButtonContainer>
      </div>
      <div>
        <CancelButton
          onClick={(e) => {
            if (removeMessage === null) {
              setRemoveMessage(
                "Sicuro di voler eliminare l'account? Clicca di nuovo per confermare"
              );
            } else {
              axios
                .delete(
                  `https://api.hypersave.net:3000/account/${data.id}?pass=${data.pass}`
                )
                .then((result) => {
                  if (result.data.esito) {
                    setTimeout(
                      setRemoveMessage("Account elimnato con successo"),
                      2000
                    );
                    Cookie.remove("Login");
                    sessionStorage.removeItem("Login");
                    window.location.href = "/hub";
                  } else {
                    setRemoveMessage("Errore Imprevisto");
                    setTimeout(setRemoveMessage(null), 2000);
                  }
                })
                .catch((err) => {
                  if (err) {
                    console.log(err);
                  }
                });
            }
          }}
        >
          Cancella Account
        </CancelButton>
        <h5>{removeMessage}</h5>
      </div>
    </HomeContainer>
  );
};
const HomeContainer = styled.div`
  width: 750px;
  height: 600px;
  margin: 5px;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  place-items: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const User = styled.div`
  width: 200px;
  aspect-ratio: 1/1;
  border-radius: 25px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserImage = styled.img`
  width: 180px;
  aspect-ratio: 1/1;
`;

const Dot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  aspect-ratio: 1/1;
  background-color: #9c70f1;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  transform: translate(90px, 150px);
  cursor: pointer;
  transition: 0.5s all;
  &:hover {
    transform: translate(90px, 150px) scale(1.1);
  }

  &:active {
    transform: translate(90px, 150px) scale(0.9);
  }
`;
const DotImage = styled.img`
  width: 40px;
  aspect-ratio: 1/1;
`;

const Input = styled.input`
  text-align: center;
  border: 0;
  border-bottom: white 1px solid;
  background-color: transparent;
  width: 250px;
  margin-bottom: 25px;
  outline: none;
  padding: 10px;
  font-size: 1rem;

  @media (max-width: 450px) {
    margin-bottom: 20px;
  }
  @media (max-width: 375px) {
    font-size: 1rem;
  }
  @media (max-width: 355px) {
    margin-bottom: 5px;
    font-size: 0.9rem;
  }
`;
const SectionTitle = styled.h2``;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;

  .active {
    display: block;
  }
`;

const SectionButton = styled.button`
  width: 200px;
  height: 50px;
  background-color: rgba(255, 255, 255);
  color: rgb(134, 94, 212);
  font-weight: 600;
  font-size: 1.1em;
  border-radius: 10px;
  text-align: center;
  user-select: none;
  border: 0;
  transition: 0.6s all;

  &:hover {
    background-color: rgb(134, 94, 212);
    color: rgba(255, 255, 255);
  }

  &:active {
    scale: 0.9;
  }
`;

const UndoButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: red;
  align-content: center;
  border: 0;
  transition: 1s all;
  font-size: 20px;
  display: none;

  &:hover {
    scale: 1.1;
  }

  &:active {
    scale: 0.9;
  }
`;

const CancelButton = styled.button`
  background-color: red;
  outline: 0;
  border: 0;
  border-radius: 25px;
  width: 200px;
  height: 100px;
  font-size: 20px;
  cursor: pointer;
  transition: 0.5s all;

  &:hover {
    scale: 1.1;
  }

  &:active {
    scale: 0.9;
  }
`;
//#endregion

//#region Settings
export const Settings = () => {
  return <SetContainer>Ciao2</SetContainer>;
};

const SetContainer = styled.div`
  width: 750px;
  height: 600px;
  margin: 5px;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  place-items: center;

  & > div {
    display: flex;
    flex-direction: column;
  }
`;
// #endregion

//#region Key
export const Key = () => {
  return <KeyContainer>Ciao3</KeyContainer>;
};

const KeyContainer = styled.div`
  width: 750px;
  height: 600px;
  margin: 5px;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  place-items: center;

  & > div {
    display: flex;
    flex-direction: column;
  }
`;

//#endregion

//#region err
export const Error = () => {
  return <>Errore di rete imprevisto riprovare più tardi</>;
};
//#endregion
