import React from "react";
import logo from "../assets/Image/LogoCompleto.svg";
import { Button } from "./button";

export const NavBar = ({ caratteristiche }) => {
  return (
    <div className="nav">
      <img
        src={logo}
        alt="hypersave"
        className="logoNav"
        onClick={() => {
          window.location.reload();
        }}
      />
      <div className="navContent">
        <Button caratteristiche={caratteristiche.bottone} />
        <div
          className="accountImg"
          onClick={caratteristiche.img.click}
          style={caratteristiche.img.style}
        >
          <img src={caratteristiche.img.src} alt="AccountImg" />
        </div>
      </div>
    </div>
  );
};
