import "./home.css";
import "swiper/css";
import React from "react";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import { Mousewheel, EffectCards } from "swiper/modules";
import VanillaTilt from "vanilla-tilt";
import axios from "axios";
import {
  hand,
  joinBeta,
  analytic,
  home,
  settings,
  appSelection,
  instagram,
  tiktok,
  youtube,
  twitter,
  GraficPresetation,
  divano,
  stonks,
  porcello,
  tempAccount,
  NavBar,
  Button,
  ButtonUi,
  Card,
  Footer,
  icona_appUi,
  icona_homeUi,
  icona_analyticsUi,
  icona_settingsUi,
} from "../components/import";

const Presentation = ({ height }) => {
  const swiper = useSwiper();

  const eventoBottone = () => {
    swiper.slideTo(1);
  };

  const bottone_Presentation = {
    text: "Learn More",
    className: "presentation_button",
    evento: eventoBottone,
  };

  const swiperSlide = useSwiperSlide();
  const [animation, setAnimation] = useState("");

  useEffect(() => {
    setTimeout(() => {
      swiperSlide.isActive ? setAnimation("animation") : setAnimation("");
    }, 500);

    return clearInterval;
  }, [swiperSlide.isActive]);

  return (
    <div className="presentation" style={{ height: height }}>
      <img
        src={GraficPresetation}
        alt="Grafica"
        className="presentation_image"
      />
      <div className={"presentation_item " + animation}>
        <h2 className="presentation_title ">The Easiest way</h2>
        <p className="presentation_text">
          to reduce your computer Consumption!
        </p>
        <Button caratteristiche={bottone_Presentation} />
      </div>
    </div>
  );
};

const PresentationMobile = () => {
  const bottone_PresentationMobile = {
    text: "Learn More",
    className: "presentation_button",
  };

  return (
    <div className="presentation">
      <img
        src={GraficPresetation}
        alt="Grafica"
        className="presentation_image"
      />
      <div className="presentation_item ">
        <h2 className="presentation_title">The Easiest way</h2>
        <p className="presentation_text">
          to reduce your computer Consumption!
        </p>
        <a href="#contentE">
          <Button caratteristiche={bottone_PresentationMobile} />
        </a>
      </div>
    </div>
  );
};

const Explaination = () => {
  const swiperSlide = useSwiperSlide();
  const [animation, setAnimation] = useState("");

  useEffect(() => {
    setTimeout(() => {
      swiperSlide.isActive ? setAnimation("animation") : setAnimation("");
    }, 500);

    return clearInterval;
  }, [swiperSlide.isActive]);

  return (
    <div className="explaination">
      <div className={"contentE " + animation}>
        <h1 className="title">What is HyperSave</h1>
        <p className="text">
          HyperSave is the first fully autonomous tool that assists you in
          reducing your computer’s energy usage and optimizing it to save money
          while minimizing your impact on the environment.
        </p>
      </div>
      <img src={hand} alt="hand" className="img" />
    </div>
  );
};

const ExplainationMobile = () => {
  return (
    <div className="explaination">
      <div className="contentE" id="contentE">
        <h1 className="title">What is HyperSave</h1>
        <p className="text">
          HyperSave is the first fully autonomous tool that assists you in
          reducing your computer’s energy usage and optimizing it to save money
          while minimizing your impact on the environment.
        </p>
      </div>
      <img src={hand} alt="hand" className="img" />
    </div>
  );
};

const CardSection = ({ height }) => {
  const Card_content = [
    {
      title: "EFFICIENCY",
      img: stonks,
      text: "HyperSave is completely automatic, it detects when you are not using the computer.",
    },
    {
      title: "SIMPLICITY",
      img: divano,
      text: "Slick and Intuitive UI. Explore and get to know the program with just a few clicks.",
    },
    {
      title: "SAVE UP",
      img: porcello,
      text: "The save mode start without the user touching a key, making it easy and convientent to use.",
    },
  ];

  const swiperSlide = useSwiperSlide();
  const [animation, setAnimation] = useState("");

  useEffect(() => {
    setTimeout(() => {
      swiperSlide.isActive ? setAnimation("animation") : setAnimation("");
    }, 500);

    return clearInterval;
  }, [swiperSlide.isActive]);

  return (
    <div className="card_div" style={{ height: height }}>
      <h2 className={"card_div_title " + animation}>
        Why would you choose HyperSave?
      </h2>
      <p className={"card_div_subtitle " + animation}>
        Here is what we can offer you!
      </p>
      <div className="card_container">
        <Card c={Card_content[0]} />
        <Card c={Card_content[1]} />
        <Card c={Card_content[2]} />
      </div>
    </div>
  );
};

const CardSectionMobile = () => {
  const Card_content = [
    {
      title: "EFFICIENCY",
      img: stonks,
      text: "HyperSave is completely automatic, it detects when you are not using the computer.",
    },
    {
      title: "SIMPLICITY",
      img: divano,
      text: "Slick and Intuitive UI. Explore and get to know the program with just a few clicks.",
    },
    {
      title: "SAVE UP",
      img: porcello,
      text: "The save mode start without the user touching a key, making it easy and convientent to use.",
    },
  ];

  return (
    <div className="card_div" id="card_div">
      <div>
        <h2 className="card_div_title ">Why would you choose HyperSave?</h2>
        <p className="card_div_subtitle">Here is what we can offer you!</p>
      </div>
      <Swiper
        autoHeight="true"
        width={230}
        effect={"cards"}
        modules={[EffectCards]}
        centeredSlidesBounds={true}
        centeredSlides={true}
        loop={true}
      >
        <SwiperSlide>
          <Card c={Card_content[0]} />
        </SwiperSlide>
        <SwiperSlide>
          <Card c={Card_content[1]} />
        </SwiperSlide>
        <SwiperSlide>
          <Card c={Card_content[2]} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

const Ui = ({ height }) => {
  let ArrayTesto = [
    `The Analytics section offers a comprehensive control center for monitoring energy consumption and computer activity. It provides detailed, real-time analysis in an easy-to-understand format. 
    Users can view energy usage, track computer activity, and identify energy-consuming applications whenever they want. Intuitive graphs and detailed statistics give a clear view of energy consumption and computer usage.
    Additionally, it shows savings from efficient energy use, helping users optimize and save money.`,

    `Welcome in the “Home” section, where you’ll have your first experience with our product. In this page are displayed all the app’s main functions and device information, such as the software’s power button and reports on device’s efficiency.
    Furthermore all data are supplied in the most simple and intuitive way and it is possible to manage some of the application’s settings directly through this tab.`,
    `In the App Selection the user is able to see all the open applications, the user chooses which applications will be suspended by the software, also providing information on the author and the energy impact.`,
    `Settings section is where all the magic takes place, because in this tab users can fix software’s settings, customizing it in all its aspects based on people devices and preferences. 
    In this tab not only you’ll be able to manage application’s general behavior, but also choose which programs you don’t want interacting with it, just with a few clicks.`,
  ];

  let arrayImmagini = [home, analytic, appSelection, settings];

  const [testo, setTesto] = useState(ArrayTesto[0]);

  const [immagini, setImmagini] = useState(arrayImmagini[0]);

  const [activeButton, setActiveButton] = useState({
    0: "active",
    1: "",
    2: "",
    3: "",
  });

  const cambioTesto = (e) => {
    setTesto(ArrayTesto[Number(e.target.id)]);
    setImmagini(arrayImmagini[Number(e.target.id)]);
    let temp = activeButton;
    temp = { 0: "", 1: "", 2: "", 3: "" };
    temp[e.target.id] = "active";
    setActiveButton(temp);
  };

  let caratteristicheBottoni = [
    {
      className: "BtnUi " + activeButton[0],
      evento: cambioTesto,
      id: "0",
      text: "HOME",
    },
    {
      className: "BtnUi " + activeButton[1],
      evento: cambioTesto,
      id: "1",
      text: "ANALYTICS",
    },
    {
      className: "BtnUi " + activeButton[2],
      evento: cambioTesto,
      id: "2",
      text: "APP",
    },
    {
      className: "BtnUi " + activeButton[3],
      evento: cambioTesto,
      id: "3",
      text: "SETTING",
    },
  ];

  const bottoniUi = caratteristicheBottoni.map((Element) => {
    return <Button caratteristiche={Element} key={Element.id} />;
  });

  const swiperSlide = useSwiperSlide();
  const [animation, setAnimation] = useState("");

  useEffect(() => {
    setTimeout(() => {
      swiperSlide.isActive ? setAnimation("animation") : setAnimation("");
    }, 500);

    return clearInterval;
  }, [swiperSlide.isActive]);
  return (
    <div className="UI" style={{ height: height }}>
      <h1 className={"UiTile " + animation}>Our Graphical User Interface</h1>
      <div className="UiBtnContainer">{bottoniUi}</div>
      <div className="UIContainer">
        <img src={immagini} alt="UI" className="UiImage" />
        <p className="UIText">{testo}</p>
      </div>
    </div>
  );
};

const UiMobile = () => {
  let ArrayTesto = [
    `Welcome in the “Home” section, where you’ll have your first experience with our product. In this page are displayed all the app’s main functions and device information, such as the software’s power button and reports on device’s efficiency.
    Furthermore all data are supplied in the most simple and intuitive way and it is possible to manage some of the application’s settings directly through this tab.`,

    `The Analytics section offers a comprehensive control center for monitoring energy consumption and computer activity. It provides detailed, real-time analysis in an easy-to-understand format. 
    Users can view energy usage, track computer activity, and identify energy-consuming applications whenever they want. Intuitive graphs and detailed statistics give a clear view of energy consumption and computer usage.
    Additionally, it shows savings from efficient energy use, helping users optimize and save money.`,

    `In the App Selection the user is able to see all the open applications, the user chooses which applications will be suspended by the software, also providing information on the author and the energy impact.`,

    `Settings section is where all the magic takes place, because in this tab users can fix software’s settings, customizing it in all its aspects based on people devices and preferences. 
    In this tab not only you’ll be able to manage application’s general behavior, but also choose which programs you don’t want interacting with it, just with a few clicks.`,
  ];

  let arrayImmagini = [home, analytic, appSelection, settings];

  const [testo, setTesto] = useState(ArrayTesto[0]);
  const [immagini, setImmagini] = useState(arrayImmagini[0]);
  const [activeButton, setActiveButton] = useState({
    0: "active",
    1: "",
    2: "",
    3: "",
  });

  const cambioTesto = (e) => {
    setTesto(ArrayTesto[Number(e.target.id)]);
    setImmagini(arrayImmagini[Number(e.target.id)]);
    let temp = activeButton;
    temp = { 0: "", 1: "", 2: "", 3: "" };
    temp[e.target.id] = "active";
    setActiveButton(temp);
  };

  let caratteristicheBottoni = [
    {
      className: "BtnUi " + activeButton[0],
      evento: cambioTesto,
      id: "0",
      icona: icona_homeUi,
    },
    {
      className: "BtnUi " + activeButton[1],
      evento: cambioTesto,
      id: "1",
      icona: icona_analyticsUi,
    },
    {
      className: "BtnUi " + activeButton[2],
      evento: cambioTesto,
      id: "2",
      icona: icona_appUi,
    },
    {
      className: "BtnUi " + activeButton[3],
      evento: cambioTesto,
      id: "3",
      icona: icona_settingsUi,
    },
  ];
  const bottoni = caratteristicheBottoni.map((Element) => {
    return <ButtonUi caratteristiche={Element} key={Element.id} />;
  });
  return (
    <div className="UI">
      <h1 className="UiTile">Our UI</h1>
      <div className="UiBtnContainer">{bottoni}</div>
      <div className="UIContainer">
        <p className="UIText">{testo}</p>
        <img src={immagini} alt="UI" className="UiImage" />
      </div>
    </div>
  );
};

const End = ({ height }) => {
  const swiperSlide = useSwiperSlide();
  const [animation, setAnimation] = useState("");

  //email check
  const validateEmail = (email) => {
    return email.match(
      //eslint-disable-next-line
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validate = () => {
    let x = document.querySelector(".endButton");
    const $result = document.querySelector(".result");
    const email = document.querySelector(".endInput").value;
    const check = document.querySelector(".endCheck");
    $result.textContent = "";

    if (validateEmail(email)) {
      if (check.checked) {
        $result.textContent = email + " is valid.";
        $result.style.color = "#32CD32";
        $result.style.display = "block";
        x.addEventListener("click", AddtoDatabase);
      } else {
        $result.style.color = "orangered";
        $result.textContent = "check the agree";
      }
    } else if (!validateEmail(email) && String(email).length > 0) {
      $result.textContent = email + " is invalid.";
      $result.style.color = "orangered";
      $result.style.display = "block";
      x.addEventListener("click", null);
    } else if (email.value === null) {
      $result.textContent = "";
      $result.style.display = "none";
      x.addEventListener("click", null);
    }
    return false;
  };

  //Add to database a record
  async function AddtoDatabase() {
    const email = document.querySelector(".endInput").value;
    const data = new Date().toISOString().slice(0, 10);
    const nAgt = navigator.userAgent;
    const resultText = document.querySelector(".result");
    let os = null;
    const clientStrings = [
      { s: "Windows 10/11", r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
      { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
      { s: "Windows Vista", r: /Windows NT 6.0/ },
      { s: "Windows Server 2003", r: /Windows NT 5.2/ },
      { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
      { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
      { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
      { s: "Windows 98", r: /(Windows 98|Win98)/ },
      { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
      {
        s: "Windows NT 4.0",
        r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/,
      },
      { s: "Windows CE", r: /Windows CE/ },
      { s: "Windows 3.11", r: /Win16/ },
      { s: "Android", r: /Android/ },
      { s: "Open BSD", r: /OpenBSD/ },
      { s: "Sun OS", r: /SunOS/ },
      { s: "Chrome OS", r: /CrOS/ },
      { s: "Linux", r: /(Linux|X11(?!.*CrOS))/ },
      { s: "iOS", r: /(iPhone|iPad|iPod)/ },
      { s: "Mac OS X", r: /Mac OS X/ },
      { s: "Mac OS", r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: "QNX", r: /QNX/ },
      { s: "UNIX", r: /UNIX/ },
      { s: "BeOS", r: /BeOS/ },
      { s: "OS/2", r: /OS\/2/ },
      {
        s: "Search Bot",
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
      },
    ];
    for (let id in clientStrings) {
      let cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }
    let url = `https://api.hypersave.net:3000/addMail`;

    axios
      .post(url, { Email: email, Date: data, Device: os })
      .then((result) => {
        let stringa = result.data;
        if (stringa.esito) {
          resultText.textContent = stringa.messaggio;
          resultText.style.color = "#32CD32";
        } else {
          resultText.textContent = stringa.messaggio;
          resultText.style.color = "orangered";
        }
      })
      .catch((err) => {
        console.log(err);
        resultText.textContent = "Errore di connessione riprovare più tardi";
      });
  }

  useEffect(() => {
    setTimeout(() => {
      swiperSlide.isActive ? setAnimation("animation") : setAnimation("");
    }, 500);

    return clearInterval;
  }, [swiperSlide.isActive]);

  const bottoneEnd = {
    className: "endButton",
    text: "sign up",
  };

  return (
    <div className="fine" style={{ height: height }}>
      <h1 className="endTitle">Join the Beta</h1>
      <div className="endContainer">
        <img src={joinBeta} alt="Join Beta" className="endImg" />
        <div className={"endContent " + animation}>
          <h1>
            Interested in previewing <span>our</span> app?
          </h1>
          <p className="contentText">
            Participate in the beta and provide us with your valuable feedback.
          </p>
          <div className="endCheckContainer">
            <input
              type="checkbox"
              name=""
              id=""
              className="endCheck"
              onChange={validate}
            />
            <label htmlFor="" className="endLabel">
              Agree to mailing
            </label>
          </div>
          <div className="endInputContainer">
            <input
              type="text"
              className="endInput"
              placeholder=" E-mail Address"
              minLength="3"
              maxLength="64"
              required=""
              spellCheck="false"
              onInput={validate}
            />
            <Button caratteristiche={bottoneEnd} />
          </div>
          <p className="result" style={{ display: "none" }}></p>
        </div>
      </div>
    </div>
  );
};

const EndMobile = () => {
  //email check
  const validateEmail = (email) => {
    return email.match(
      //eslint-disable-next-line
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validate = () => {
    let x = document.querySelector(".endButton");
    const $result = document.querySelector(".result");
    const email = document.querySelector(".endInput").value;
    const check = document.querySelector(".endCheck");
    $result.textContent = "";

    if (validateEmail(email)) {
      if (check.checked) {
        $result.textContent = email + " is valid.";
        $result.style.color = "#32CD32";
        $result.style.display = "block";
        x.addEventListener("click", AddtoDatabase);
      } else {
        $result.style.color = "orangered";
        $result.textContent = "check the agree";
      }
    } else if (!validateEmail(email) && String(email).length > 0) {
      $result.textContent = email + " is invalid.";
      $result.style.color = "orangered";
      $result.style.display = "block";
      x.addEventListener("click", null);
    } else if (email.value === null) {
      $result.textContent = "";
      $result.style.display = "none";
      x.addEventListener("click", null);
    }
    return false;
  };

  //Add to database a record
  async function AddtoDatabase() {
    const email = document.querySelector(".endInput").value;
    const data = new Date().toISOString().slice(0, 10);
    const nAgt = navigator.userAgent;
    const resultText = document.querySelector(".result");
    let os = null;
    const clientStrings = [
      { s: "Windows 10/11", r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
      { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
      { s: "Windows Vista", r: /Windows NT 6.0/ },
      { s: "Windows Server 2003", r: /Windows NT 5.2/ },
      { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
      { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
      { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
      { s: "Windows 98", r: /(Windows 98|Win98)/ },
      { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
      {
        s: "Windows NT 4.0",
        r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/,
      },
      { s: "Windows CE", r: /Windows CE/ },
      { s: "Windows 3.11", r: /Win16/ },
      { s: "Android", r: /Android/ },
      { s: "Open BSD", r: /OpenBSD/ },
      { s: "Sun OS", r: /SunOS/ },
      { s: "Chrome OS", r: /CrOS/ },
      { s: "Linux", r: /(Linux|X11(?!.*CrOS))/ },
      { s: "iOS", r: /(iPhone|iPad|iPod)/ },
      { s: "Mac OS X", r: /Mac OS X/ },
      { s: "Mac OS", r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: "QNX", r: /QNX/ },
      { s: "UNIX", r: /UNIX/ },
      { s: "BeOS", r: /BeOS/ },
      { s: "OS/2", r: /OS\/2/ },
      {
        s: "Search Bot",
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
      },
    ];
    for (let id in clientStrings) {
      let cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }
    let url = `https://api.hypersave.net:3000/addMail`;

    axios
      .post(url, { Email: email, Date: data, Device: os })
      .then((result) => {
        let stringa = result.data;
        if (stringa.esito) {
          resultText.textContent = stringa.messaggio;
          resultText.style.color = "#32CD32";
        } else {
          resultText.textContent = stringa.messaggio;
          resultText.style.color = "orangered";
        }
      })
      .catch((err) => {
        console.log(err);
        resultText.textContent = "Errore di connessione riprovare più tardi";
      });
  }

  const bottoneEnd = {
    className: "endButton",
    text: "sign up",
  };

  return (
    <div className="fine">
      <h1 className="endTitle">Join the Beta</h1>
      <div className="endContainer">
        <img src={joinBeta} alt="Join Beta" className="endImg" />
        <div className="endContent">
          <h1>
            Interested in previewing <span>our</span> app?
          </h1>
          <p className="contentText">
            Participate in the beta and provide us with your valuable feedback.
          </p>
          <div className="endCheckContainer">
            <input
              type="checkbox"
              name=""
              id=""
              className="endCheck"
              onChange={validate}
            />
            <label htmlFor="" className="endLabel">
              Agree to mailing
            </label>
          </div>
          <div className="endInputContainer">
            <input
              type="text"
              className="endInput"
              placeholder=" E-mail Address"
              minLength="3"
              maxLength="64"
              required=""
              spellCheck="false"
              onInput={validate}
            />
            <Button caratteristiche={bottoneEnd} />
          </div>
          <p className="result" style={{ display: "none" }}></p>
        </div>
      </div>
    </div>
  );
};

function Home() {
  const [windowsSize, setwindowsSize] = useState(getWindosSize());
  function generalListener() {
    if (
      (windowsSize.innerWidth > 770 &&
        window.screen.orientation.type === "portrait-primary") ||
      (windowsSize.innerWidth > 900 &&
        window.screen.orientation.type === "landscape-primary")
    ) {
      const swiper = document.querySelector(".swiperBody").swiper;
      function slideTo() {
        swiper.slideTo(5, 2000);
      }
      document.getElementById("nav_button").addEventListener("click", slideTo);
      VanillaTilt.init(document.querySelectorAll(".card"));
    }

    return () => {
      if (
        (windowsSize.innerWidth > 770 &&
          window.screen.orientation.type === "portrait-primary") ||
        (windowsSize.innerWidth > 900 &&
          window.screen.orientation.type === "landscape-primary")
      ) {
        const swiper = document.querySelector(".swiperBody").swiper;
        function slideTo() {
          swiper.slideTo(5, 2000);
        }
        document
          .getElementById("nav_button")
          .removeEventListener("click", slideTo);
      }
    };
  }
  useEffect(() => {
    function windowsResize() {
      setwindowsSize(getWindosSize());
    }

    generalListener();

    window.addEventListener("resize", windowsResize);

    return () => {
      window.removeEventListener("resize", windowsResize);
    };
    // eslint-disable-next-line
  }, []);

  const nav = {
    bottone: {
      text: "Download",
      className: "nav_button",
      id: "nav_button",
    },
    img: {
      src: tempAccount,
      click: () => (window.location = "/hub"),
      style: { display: "none" },
    },
  };

  const listaSocial = [
    {
      name: "youtube",
      img: youtube,
      href: "https://youtube.com/@hypersaveofficial?si=tHB5E-06eCwRd-Wr",
    },
    {
      name: "insta",
      img: instagram,
      href: "https://www.instagram.com/hypersaveofficial?igsh=MWJiYWo4amR0ZDVoOA==",
    },
    {
      name: "tiktok",
      img: tiktok,
      href: "https://www.tiktok.com/@hypersaveofficial?is_from_webapp=1&sender_device=pc",
    },
    { name: "twitter", img: twitter, href: "https://twitter.com/home?lang=en" },
  ];

  if (
    (windowsSize.innerWidth < 770 &&
      window.screen.orientation.type === "portrait-primary") ||
    (windowsSize.innerWidth < 900 &&
      window.screen.orientation.type === "landscape-primary")
  ) {
    return (
      <div className="App" onLoad={generalListener}>
        <NavBar caratteristiche={nav} />
        <PresentationMobile />
        <ExplainationMobile />
        <CardSectionMobile />
        <UiMobile />
        <EndMobile />
        <Footer listaSocial={listaSocial} />
      </div>
    );
  } else {
    window.scrollTo(0, 0);
    return (
      <div className="App" onLoad={generalListener}>
        <NavBar caratteristiche={nav} />
        <Swiper
          direction="vertical"
          mousewheel={true}
          autoHeight={true}
          speed={900}
          modules={[Mousewheel]}
          simulateTouch={false}
          className="swiperBody"
        >
          <SwiperSlide>
            <Presentation height={windowsSize.innerHeight - 170} />
          </SwiperSlide>
          <SwiperSlide>
            <Explaination />
          </SwiperSlide>
          <SwiperSlide>
            <CardSection height={windowsSize.innerHeight - 127} />
          </SwiperSlide>
          <SwiperSlide>
            <Ui height={windowsSize.innerHeight - 220} />
          </SwiperSlide>
          <SwiperSlide>
            <End height={windowsSize.innerHeight - 270} />
            <Footer listaSocial={listaSocial} />
          </SwiperSlide>
        </Swiper>
      </div>
    );
  }
}

function getWindosSize() {
  const { innerHeight, innerWidth } = window;

  return { innerHeight, innerWidth };
}

export default Home;
